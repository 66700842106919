/* You can add global styles to this file, and also import other style files */
@import "primeflex/primeflex.css";
@import "assets/theme/theme.css";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";

body {
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}

em {
  color: var(--primary-color);
}



.termsfeed-com---palette-dark .cc-nb-okagree {
  background-color: #05a570;
}

.termsfeed-com---palette-dark .cc-nb-reject {
  color: #05a570;
  background-color: unset;
}

.termsfeed-com---palette-dark .cc-nb-changep {
  display: none;
}

.termsfeed-com---palette-dark .cc-cp-foot-save {
  background: #05a570;
  color: #000;
}


.termsfeed-com---palette-dark .cc-pc-head-lang select {
  color: white;
}

.termsfeed-com---palette-dark .cc-pc-head-lang select:focus {
  box-shadow: 0 0 0 2px #05a570;
  color: white;
}

.termsfeed-com---pc-dialog input[type=checkbox].cc-custom-checkbox:checked+label:before {
  background: #05a570;
}

